import commonDataAccess from "../commonDataAccess";
import { IFilter } from "../../@redux/listRedux";

class activityDataAccess {
  phoneCallList = async (params: {
    pageNo;
    pageSize;
    sortData;
    filters: Array<IFilter>;
  }) => {
    let _filters = {};
    console.log(params.filters);
    params.filters.forEach((item) => {
      if (item.value && item.value.length > 0) {
        _filters["filter." + item.propertyName] = item.value + "-" + item.type;
      }
    });
    let parameters = {
      pageNo: params.pageNo,
      pageSize: params.pageSize,
      sortPreference: params.sortData,
      ..._filters,
    };
    return await commonDataAccess.getData(
      "activityPhoneCall/getAll",
      parameters
    );
  };

  addPhoneCall = async (params: any) => {
    return await commonDataAccess.postData(
      "activityPhoneCall/save/phoneCall",
      params
    );
  };

  getPhoneCall = async (id: string) => {
    return await commonDataAccess.getData(
      `ActivityPhoneCall/get/phoneCallData/${id}`
    );
  };

  // Activity Appointments
  appointmentList = async (params: {
    pageNo;
    pageSize;
    sortData;
    filters: Array<IFilter>;
  }) => {
    let _filters = {};
    console.log(params.filters);
    params.filters.forEach((item) => {
      if (item.value && item.value.length > 0) {
        _filters["filter." + item.propertyName] = item.value + "-" + item.type;
      }
    });
    let parameters = {
      pageNo: params.pageNo,
      pageSize: params.pageSize,
      sortPreference: params.sortData,
      ..._filters,
    };
    return await commonDataAccess.getData(
      "activityAppointment/getAll",
      parameters
    );
  };
  getAppointment = async (id: string) => {
    return await commonDataAccess.getData(
      `activityAppointment/get/appointmentData/${id}`
    );
  };
  addAppointment = async (params: any) => {
    return await commonDataAccess.postData(
      "activityAppointment/save/appointment",
      params
    );
  };
}

export default new activityDataAccess();
