import { ActionType, funderActionType } from "./funderActions";

export interface IFunderState{
    funders:any[],
    funderBasicInfo: any,
    dataCount:number,
    filters:any[],
    isLoading:boolean
}

const initialState:IFunderState={
    funders:[],
    funderBasicInfo: {},
    dataCount:0,
    filters:[],
    isLoading:false
} 
export const funderReducer = (
    state: IFunderState = initialState,
    action: ActionType
  ) => {
    switch (action.type) {
      case funderActionType.GET_FUNDER_LIST_SUCCESS:
        state = {
          ...state,
          funders: action.payload?.data,
          dataCount: action.payload?.dataCount,
        };
        return state;
      case funderActionType.GET_FUNDER_BASIC_INFO:
        state = {
          ...state,
          funderBasicInfo: action.payload,
        };
        return state;
      case funderActionType.LOAD_FUNDER:
        state = {
          ...state,
          isLoading: action.payload,
        };
        return state;
      case funderActionType.RESET_FUNDER_BASICINFO:
        state = {
          ...state,
          funderBasicInfo: action.payload,
        };
        return state;
      default:
        return state;
    }
  }