import commonDataAccess from "../commonDataAccess";
import { IFilter } from "../../@redux/listRedux";

class clientDataAccess {
  updateClient(params: { id: string }) {
    return commonDataAccess.putData("client/update/client", null, params);
  }
  clientList = async (params: {
    type;
    pageNo;
    pageSize;
    sortData;
    filters: Array<IFilter>;
  }) => {
    let _filters = {};
    console.log(params.filters);
    params.filters.forEach((item) => {
      if (item.value && item.value.length > 0) {
        _filters["filter." + item.propertyName] = item.value + "-" + item.type;
      }
    });
    let parameters = {
      type: params.type,
      pageNo: params.pageNo,
      pageSize: params.pageSize,
      sortPreference: params.sortData,
      ..._filters,
    };
    return await commonDataAccess.getData("client/getAll", parameters);
  };

  clientByUserTypeList = async (params: {
    userContactId;
    userType;
    pageNo;
    pageSize;
    sortData;
    filters: Array<IFilter>;
  }) => {
    let _filters = {};
    console.log(params.filters);
    params.filters.forEach((item) => {
      if (item.value && item.value.length > 0) {
        _filters["filter." + item.propertyName] = item.value + "-" + item.type;
      }
    });
    let parameters = {
      userContactId: params.userContactId,
      userType: params.userType,
      pageNo: params.pageNo,
      pageSize: params.pageSize,
      sortPreference: params.sortData,
      ..._filters,
    };
    return await commonDataAccess.getData(
      "client/get/getUserContactClients",
      parameters
    );
  };

  getClientBasicInfo = async (params: { id: number | bigint | any }) => {
    var result = await commonDataAccess.getData(
      "client/get/clientBasicInfo/" + params,
      {}
    );
    return result;
  };
  saveClientBasicInfo = async (model: any) => {
    var result = await commonDataAccess.postData(
      "client/save/clientBasicInfo",
      model
    );
    return result;
  };
  convertToClient = async (params: { id: number | bigint | any }) => {
    var result = await commonDataAccess.postData(
      "client/save/convertToClient/" + params,
      {}
    );
    console.log("Hit in API");
    
    return result;
  };
  deleteClientInfo = async (params: { ids: string }) => {
    var result = await commonDataAccess.deleteData("client/delete/" + params);
    return result;
  };

  getReferralPartnerListsForDropdown = async () => {
    var result = await commonDataAccess.getData(
      "user/get/referralPartnerListsForDropdown",
      {}
    );
    return result;
  };
  getBDMListsForDropdown = async () => {
    var result = await commonDataAccess.getData(
      "user/get/bdmListsForDropdown",
      {}
    );
    return result;
  };
  getBDMNamebyReferrerId = async (referrerId: string | any) => {
    var result = await commonDataAccess.getData(
      "user/get/GetBDMNamebyReferrerId",
      {
        referrerId: referrerId,
      }
    );
    return result;
  };
  getAllProductListsForDropdown = async () => {
    var result = await commonDataAccess.getData(
      "clientProduct/get/clientProductListsForDropdown",
      {}
    );
    return result;
  };
  getClientProductById = async (
    clientId: string | any,
    productId: string | any
  ) => {
    var result = await commonDataAccess.getData(
      "client/get/getClientProductById",
      {
        clientId: clientId,
        productId: productId,
      }
    );
    return result;
  };

  saveClientProduct = async (model: any, clientId: any) => {
    var result = await commonDataAccess.postData(
      "client/save/saveClientProduct",
      model,
      { clientId: clientId }
    );
    return result;
  };

  getClientProducts = async (clientId: string | any) => {
    var result = await commonDataAccess.getData(
      "client/get/getClientProducts",
      {
        clientId: clientId,
      }
    );
    return result;
  };

  deleteClientProduct = async (id: any) => {
    var result = await commonDataAccess.deleteData("client/delete/" + id);
    return result;
  };

  getAllClientListsForDropdown = async () => {
    var result = await commonDataAccess.getData(
      "client/get/clientListsForDropdown",
      {}
    );
    return result;
  };
  getAllClientListsProspectFilteredForDropdown = async (isProspective: any) => {
    var result = await commonDataAccess.getData(
      "client/get/clientListsForDropdown",
      { isProspective: isProspective }
    );
    return result;
  };

  getClientProductsById = async (clientId: string | any) => {
    var result = await commonDataAccess.getData(
      "client/get/getClientProductsById",
      {
        clientId: clientId,
      }
    );
    return result;
  };

  saveProductCategory = (payload) => {
    return commonDataAccess.postData(
      "clientProduct/save/clientProduct",
      payload
    );
  };

  getReferrerClients = async (
    userContactId: string | any,
    userType: string | any
  ) => {
    var result = await commonDataAccess.getData(
      "client/get/getUserContactClients",
      {
        userContactId: userContactId,
        userType: userType,
      }
    );
    return result;
  };

  saveSettlement = (id: any, settledDate: any) => {
    return commonDataAccess.postData("client/save/saveSettlement", null, {
      id: id,
      settlementDate: settledDate,
    });
  };
}

export default new clientDataAccess();
