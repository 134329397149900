import { Action } from "redux";
import { Dispatch } from "@reduxjs/toolkit";
import clientDataAccess from "src/dataAccess/clients/clientDataAccess";
import { setEditPageTitle } from "../common/commonAction";
import clientProductDataAccess from "src/dataAccess/clientProducts/clientProductDataAccess";

export enum clientProductActionType {

    LOAD_PRODUCT_CLIENT = "[ClientProduct] Loading Client Products",
    GET_CLIENT_PRODUCT_LIST_SUCCESS = "[ClientProduct] Get Client Product list success",
    GET_CLIENT_PRODUCT_BASICINFO = "[ClientProduct] Get Client Product Basic Info",
    // RESET_CLIENT_BASICINFO = "[Client] Reset Client Basic Info",
    RESET_CLIENT_PRODUCT_BASICINFO = "[Client] Reset Client Product Basic Info",
    GET_CLIENT_PRODUCTS = "[ClientProduct] Get Client products",
    GET_PRODUCT_STATUS_LIST_SUCCESS = "[ClientProduct] Get Product status list success",
    // ADD_CLIENT_PRODUCT = "[Client] Add Client product"
}

interface ClientProductAction extends Action {
    payload: any;
}

export const actions = {

    getClientProductList:
        (pageNo = 1, pageSize = 25, sortData = "", filters = []) =>
            (dispatch: Dispatch, getState) => {
                dispatch({ type: clientProductActionType.LOAD_PRODUCT_CLIENT, payload: true });
                clientProductDataAccess
                    .clientProductList({ pageNo, pageSize, sortData, filters })
                    .then((res) => {
                        if (res?.success) {
                            dispatch({
                                type: clientProductActionType.GET_CLIENT_PRODUCT_LIST_SUCCESS,
                                payload: res,
                            });
                            dispatch({ type: clientProductActionType.LOAD_PRODUCT_CLIENT, payload: false });
                        }
                    })
                    .catch((error: Error) => {
                        // throw new SubmissionError({ _error: error.message });
                    });
            },

    getClientProductBasicInfo:
        (clientProductId) => (dispatch: Dispatch, getState) => {
            dispatch({ type: clientProductActionType.LOAD_PRODUCT_CLIENT, payload: true });
            clientProductDataAccess.getClientProductBasicInfo(clientProductId).then((res) => {
                if (res?.success) {
                    console.log(res?.data);
                    dispatch({
                        type: clientProductActionType.GET_CLIENT_PRODUCT_BASICINFO,
                        payload: res?.data
                    });
                    dispatch({ type: clientProductActionType.LOAD_PRODUCT_CLIENT, payload: false });
                }
            })
                .catch((error: Error) => {
                    // throw new SubmissionError({ _error: error.message });
                });
        },
    getProductStatusList:
        (pageNo = 1, pageSize = 25, sortData = "", filters = []) =>
            (dispatch: Dispatch, getState) => {
                dispatch({ type: clientProductActionType.LOAD_PRODUCT_CLIENT, payload: true });
                clientProductDataAccess
                    .getProductStatusList({ pageNo, pageSize, sortData, filters })
                    .then((res) => {
                        if (res?.success) {
                            dispatch({
                                type: clientProductActionType.GET_PRODUCT_STATUS_LIST_SUCCESS,
                                payload: res,
                            });
                            dispatch({ type: clientProductActionType.LOAD_PRODUCT_CLIENT, payload: false });
                        }
                    })
                    .catch((error: Error) => {
                        // throw new SubmissionError({ _error: error.message });
                    });
            },

    // getClientBasicInfo:
    //     (clientId) => (dispatch: Dispatch, getState) => {
    //         dispatch({ type: clientActionType.LOAD_CLIENT, payload: true });
    //         clientDataAccess.getClientBasicInfo(clientId).then((res) => {
    //             if (res?.success) {
    //                 let data = { ...res?.data };
    //                 data.status = data.status === "Active" ? true : false;
    //                 dispatch({
    //                     type: clientActionType.GET_CLIENT_BASICINFO,
    //                     payload: data
    //                 });
    //                 dispatch(setEditPageTitle(res?.data?.name));
    //                 dispatch({ type: clientActionType.LOAD_CLIENT, payload: false });
    //             }
    //         })
    //             .catch((error: Error) => {
    //                 // throw new SubmissionError({ _error: error.message });
    //             });

    //     },

    // saveClientProduct:
    //     (clientId) => (dispatch: Dispatch, getState) => {
    //         dispatch({ type: clientActionType.ADD_CLIENT_PRODUCT, payload: false });
    //     },

    // getClientProducts:
    //     (clientId) => (dispatch: Dispatch, getState) => {
    //         dispatch({ type: clientActionType.LOAD_CLIENT, payload: true });
    //         clientDataAccess.getClientProducts(clientId).then((res) => {
    //             if (res?.success) {
    //                 dispatch({
    //                     type: clientActionType.GET_CLIENT_PRODUCTS,
    //                     payload: res?.data
    //                 });
    //                 dispatch({ type: clientActionType.LOAD_CLIENT, payload: false });
    //             }
    //         })
    //             .catch((error: Error) => {
    //                 // throw new SubmissionError({ _error: error.message });
    //             });
    //     }
};

export type ActionType = ClientProductAction;
