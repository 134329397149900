import { ActionType, activityActionType } from "./activityActions";

export interface IActivityState {
    phoneCalls: any[];
    appointments: any[];
    filters: any[];
    dataCount: number;
    isLoading: boolean;
}

const initialState: IActivityState = {
    phoneCalls: [],
    appointments: [],
    filters: [],
    dataCount: 0,
    isLoading: false
};

export const activityReducer = (
    state: IActivityState = initialState,
    action: ActionType
) => {
    switch (action.type) {
        case activityActionType.GET_PHONE_CALL_LIST_SUCCESS:
            state = {
                ...state,
                phoneCalls: action.payload?.data,
                dataCount: action.payload?.dataCount,
            };
            return state;
        case activityActionType.LOAD_PHONE_CALLS:
            state = {
                ...state,
                isLoading: action.payload,
            };
            return state;
        case activityActionType.LOAD_APPOITNMENTS:
            state = {
                ...state,
                isLoading: action.payload,
            };
            return state;
        case activityActionType.GET_APPOITNMENT_LIST_SUCCESS:
            state = {
                ...state,
                appointments: action.payload?.data,
                dataCount: action.payload?.dataCount,
            };
            return state;
        default:
            return state;
    }
};
