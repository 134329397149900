import { Action } from "redux";
import { Dispatch } from "@reduxjs/toolkit";
import clientDataAccess from "src/dataAccess/clients/clientDataAccess";
import { setEditPageTitle } from "../common/commonAction";
import { clientProductActionType } from "../clientProducts/clientProductActions";

export enum clientActionType {

  LOAD_CLIENT = "[Client] Loading Client",
  GET_CLIENT_LIST_SUCCESS = "[Client] Get Client list success",
  GET_CLIENT_BY_USER_TYPE_LIST_SUCCESS = "[ClientByUserType] Get Client by user type list success",
  GET_CLIENT_BASICINFO = "[Client] Get Client Basic Info",
  RESET_CLIENT_BASICINFO = "[Client] Reset Client Basic Info",
  GET_CLIENT_PRODUCTS = "[Client] Get Client products",
  ADD_CLIENT_PRODUCT = "[Client] Add Client product"
}

interface ClientAction extends Action {
  payload: any;
}

export const actions = {

  getClientList:
    (type = "existing", pageNo = 1, pageSize = 25, sortData = "", filters = []) =>
      (dispatch: Dispatch, getState) => {
        dispatch({ type: clientActionType.LOAD_CLIENT, payload: true });
        clientDataAccess
          .clientList({ type, pageNo, pageSize, sortData, filters })
          .then((res) => {
            if (res?.success) {
              dispatch({
                type: clientActionType.GET_CLIENT_LIST_SUCCESS,
                payload: res,
              });
              dispatch({ type: clientActionType.LOAD_CLIENT, payload: false });
            }
          })
          .catch((error: Error) => {
            // throw new SubmissionError({ _error: error.message });
          });
      },

  getClientBasicInfo:
    (clientId) => (dispatch: Dispatch, getState) => {
      dispatch({ type: clientActionType.LOAD_CLIENT, payload: true });
      clientDataAccess.getClientBasicInfo(clientId).then((res) => {
        if (res?.success) {
          let data = { ...res?.data };
          data.status = data.status === "Active" ? true : false;
          dispatch({
            type: clientActionType.GET_CLIENT_BASICINFO,
            payload: data
          });
          dispatch(setEditPageTitle(res?.data?.name));
          dispatch({ type: clientActionType.LOAD_CLIENT, payload: false });
        }
      })
        .catch((error: Error) => {
          // throw new SubmissionError({ _error: error.message });
        });

    },

  saveClientProduct:
    (clientId) => (dispatch: Dispatch, getState) => {
      dispatch({ type: clientActionType.ADD_CLIENT_PRODUCT, payload: false });
    },

  getClientProducts:
    (clientId) => (dispatch: Dispatch, getState) => {
      dispatch({ type: clientActionType.LOAD_CLIENT, payload: true });
      clientDataAccess.getClientProducts(clientId).then((res) => {
        if (res?.success) {
          dispatch({
            type: clientActionType.GET_CLIENT_PRODUCTS,
            payload: res?.data
          });
          dispatch({ type: clientActionType.LOAD_CLIENT, payload: false });
        }
      })
        .catch((error: Error) => {
          // throw new SubmissionError({ _error: error.message });
        });
    },

  getClientsByUserTypeList:
    (userContactId: any, userType: any, pageNo = 1, pageSize = 25, sortData = "", filters = []) =>
      (dispatch: Dispatch, getState) => {
        dispatch({ type: clientActionType.LOAD_CLIENT, payload: true });
        clientDataAccess
          .clientByUserTypeList({ userContactId, userType, pageNo, pageSize, sortData, filters })
          .then((res) => {
            if (res?.success) {
              dispatch({
                type: clientActionType.GET_CLIENT_BY_USER_TYPE_LIST_SUCCESS,
                payload: res,
              });
              dispatch({ type: clientActionType.LOAD_CLIENT, payload: false });
            }
          })
          .catch((error: Error) => {
            // throw new SubmissionError({ _error: error.message });
          });
      },
};

export type ActionType = ClientAction;
