import commonDataAccess from "../commonDataAccess";
import { IFilter } from "../../@redux/listRedux";

class commissionPaymentDataAccess {
  // updateClient(params: { id: string }) {
  //   return commonDataAccess.putData("client/update/client", null, params);
  // }
  commissionPaymentList = async (params: {
    pageNo;
    pageSize;
    sortData;
    filters: Array<IFilter>;
  }) => {
    let _filters = {};
    console.log(params.filters);
    params.filters.forEach((item) => {
      if (item.value && item.value.length > 0) {
        _filters["filter." + item.propertyName] = item.value + "-" + item.type;
      }
    });
    let parameters = {
      pageNo: params.pageNo,
      pageSize: params.pageSize,
      sortPreference: params.sortData,
      ..._filters,
    };
    return await commonDataAccess.getData(
      "commissionPayment/getAll",
      parameters
    );
  };

  getCommissionPaymentInfo = async (params: { id: number | bigint | any }) => {
    var result = await commonDataAccess.getData(
      "commissionPayment/get/commissionPaymentBasicInfo/" + params,
      {}
    );
    return result;
  };
  saveCommissionPaymentInfo = async (model: any) => {
    var result = await commonDataAccess.postData(
      "commissionPayment/save/commissionPaymentBasicInfo",
      model
    );
    return result;
  };

  deleteCommissionPayment = async (params: { ids: string }) => {
    var result = await commonDataAccess.deleteData(
      "commissionPayment/delete/" + params
    );
    return result;
  };
  getReferrerAndBdmCommissionByProductId = async (
    clientProductId: string | any
  ) => {
    var result = await commonDataAccess.getData(
      "client/get/getReferrerAndBdmCommissionByProductId",
      {
        // clientId: clientId,
        // productId: productId
        clientProductId: clientProductId,
      }
    );
    return result;
  };

  getReferrerCommissions = async (referrerId: string | any) => {
    var result = await commonDataAccess.getData(
      "commissionPayment/get/getReferralCommissions",
      {
        referrerId: referrerId,
      }
    );
    return result;
  };

  savePaymentMadeDate = (id: any) => {
    return commonDataAccess.postData(
      "commissionPayment/save/savePaymentMadeDate",
      null,
      { id: id }
    );
  };

  getUserTypeAndCommissionRateById = async (id: any) => {
    return await commonDataAccess.getData(
      "commissionPayment/get/getUserTypeAndCommissionRateById",
      {
        id: id,
      }
    );
  };
  getCommissionRateByUserContactId = async (userContactId: any) => {
    return await commonDataAccess.getData(
      "commissionPayment/get/getCommissionRateByUserContactId",
      {
        userContactId: userContactId,
      }
    );
  };

  importCommissionPaymentExcel = (payload: any) => {
    return commonDataAccess.postData(
      "commissionPayment/import/exceldata",
      payload
    );
  };

  sendSettledEmail = async (commissionPaymentId: any) => {
    var result = await commonDataAccess.getData(
      "commissionPayment/get/sendClaimCommissionEmail",
      {
        id: commissionPaymentId,
      }
    );
    return result;
  };
}

export default new commissionPaymentDataAccess();
