import { Action } from "redux";
import { Dispatch } from "@reduxjs/toolkit";
import clientDataAccess from "src/dataAccess/clients/clientDataAccess";
import { setEditPageTitle } from "../common/commonAction";
import { clientProductActionType } from "../clientProducts/clientProductActions";
import activityDataAccess from "src/dataAccess/activities/activityDataAccess";

export enum activityActionType {

    LOAD_PHONE_CALLS = "[PhoneCall] Loading Phone Call list",
    GET_PHONE_CALL_LIST_SUCCESS = "[PhoneCall] Get Phone Call list success",
    LOAD_APPOITNMENTS = "[Appointment] Loading Appointment list",
    GET_APPOITNMENT_LIST_SUCCESS = "[Appointment] Get Appointment list success",
}

interface ActivityAction extends Action {
    payload: any;
}

export const actions = {

    getPhoneCallList:
        (pageNo = 1, pageSize = 25, sortData = "", filters = []) =>
            (dispatch: Dispatch, getState) => {
                dispatch({ type: activityActionType.LOAD_PHONE_CALLS, payload: true });
                activityDataAccess
                    .phoneCallList({ pageNo, pageSize, sortData, filters })
                    .then((res) => {
                        if (res?.success) {
                            dispatch({
                                type: activityActionType.GET_PHONE_CALL_LIST_SUCCESS,
                                payload: res,
                            });
                            dispatch({ type: activityActionType.LOAD_PHONE_CALLS, payload: false });
                        }
                    })
                    .catch((error: Error) => {
                        // throw new SubmissionError({ _error: error.message });
                    });
            },
    getAppointmentList:
    (pageNo = 1, pageSize = 25, sortData = "", filters = []) =>
        (dispatch: Dispatch, getState) => {
            dispatch({ type: activityActionType.LOAD_APPOITNMENTS, payload: true });
            activityDataAccess
                .appointmentList({ pageNo, pageSize, sortData, filters })
                .then((res) => {
                    if (res?.success) {
                        dispatch({
                            type: activityActionType.GET_APPOITNMENT_LIST_SUCCESS,
                            payload: res,
                        });
                        dispatch({ type: activityActionType.LOAD_APPOITNMENTS, payload: false });
                    }
                })
                .catch((error: Error) => {
                    // throw new SubmissionError({ _error: error.message });
                });
        }
};

export type ActionType = ActivityAction;
