import { Action, Dispatch } from "@reduxjs/toolkit";
import { setEditPageTitle } from "../common/commonAction";
import funderDataAccess from "src/dataAccess/funders/funderDataAccess";

export enum funderActionType {
    LOAD_FUNDER = "[FUNDER] FUNDER is loading",
    GET_FUNDER_LIST_SUCCESS = "[FUNDER] Get FUNDER List Success",
    GET_FUNDER_BASIC_INFO = "[FUNDER] Get FUNDER Basic Info",
    RESET_FUNDER_BASICINFO = "[FUNDER] Reset FUNDER Basic Info"
}

interface FunderAction extends Action{
    payload:any;
}

export const actions={
    getFunderList:
    (pageNo=0,pageSize=20,sortData="",filters=[])=>
        (dispatch:Dispatch,getState)=> {
            dispatch({type:funderActionType.LOAD_FUNDER,payload:true});
            funderDataAccess
            .funderList({pageNo,pageSize,sortData,filters})
            .then((res)=>{
                if(res?.success)
                {
                    dispatch(
                        {type:funderActionType.GET_FUNDER_LIST_SUCCESS,
                            payload:res
                        });
                    dispatch({type:funderActionType.LOAD_FUNDER,payload:false});
                }
            })
            .catch((error:Error)=>{

            });
        },
    getFunderBasicInfo:
    (funderId) => (dispatch: Dispatch, getState) => {
      dispatch({ type: funderActionType.GET_FUNDER_BASIC_INFO, payload: true });
      funderDataAccess.getFunderBasicInfo(funderId).then((res) => {
        if (res?.success) {
          let data = { ...res?.data };
          console.log(data);
          dispatch({
            type: funderActionType.GET_FUNDER_BASIC_INFO,
            payload: data
          });
          dispatch(setEditPageTitle(res?.data?.name));
          dispatch({ type: funderActionType.LOAD_FUNDER, payload: false });
        }
      })
        .catch((error: Error) => {
          // throw new SubmissionError({ _error: error.message });
        });

    },
};
export type ActionType = FunderAction;